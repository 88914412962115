import React from 'react';

const SectionHeader = (props) => {

    return (
        <div className="sectionHeader w-full px-10">
            <p className={`font-serif mb-10 mt-10 text-4xl md:text-5xl sectionTitle `}>
                {props.sectionTitle}
            </p>
            <p className="text-xl mb-5">
                {props.sectionSubTitle}
            </p>
            <p>
                {props.sectionContent}
            </p>
        </div>
    )
}

export default SectionHeader;