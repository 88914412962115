import React from 'react'

const Button = (props) => {

    return (
        <button 
            onClick={props.onClick}
            className={`mt-5 p-3 hover:bg-blue-800 bg-gray-500  text-white shadow-md`}>{props.text}</button>
    )
}

export default Button