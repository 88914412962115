import * as React from "react";
import SectionHeader from "./SectionHeader";
import SectionLayout from "./SectionLayout";
import Button from './Button';

import editionen from "../../scraper/editionen"
import { Link } from "gatsby";

const sectionTitle = "Editionen";
const sectionSubTitle = "Limitierte Editonen";
const sectionContent = "Unsere limitierten Künstlereditionen wurden exklusiv für Sie, unsere Mitglieder, geschaffen und sind zu Vorzugspreisen erhältlich."

const Edition = (props) => {
    const titlesplit = props.item.title.split("|")
    return (
        <Link to={props.item.link} target="_blank">
            <div className="px-3 mx-5 flex flex-col text-sm">
                <img src={props.item.img} className="mb-4 max-h-40" />
                <span className="font-semibold">{titlesplit[0]}</span><br />{titlesplit[1]}
            </div>
        </Link>
    )
}

const Editionen = (props) => {
    console.log(editionen)
    return (
        <SectionLayout id="editionen" number={props.number}>
            <SectionHeader
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                sectionContent={sectionContent}
            />
            <div className="flex flex-row mt-7">
                {
                    editionen.slice(0, 3).map(edition => <Edition item={edition} />)
                }
            </div>
            <Button text="Alle Editionen ansehen" onClick={() => window.open("https://shop.kestnergesellschaft.de/editionen.html")} />
        </SectionLayout>
    );
};

export default Editionen;
