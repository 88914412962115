import * as React from "react";
import bgImage from '../images/Kestnergesellschaft.jpg'
 
const Home = () => {
    return (
        <div id="home" className="h-40 md:h-96 bg-fixed w-screen" 
            style={{ 
            backgroundImage: "url(" + bgImage + ")",
            backgroundSize: "100%"  }}>
            <div className="bg-opacity-85 flex items-center justify-center bg-black h-full w-full">
                <div className="p-3">
                    <p className="font-light text-2xl sm:text-4xl md:text-6xl text-white">Kunst fördern mit Freunden</p>
                </div>
            </div>
        </div>
    );
};

export default Home;
