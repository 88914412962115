import * as React from "react";
import ClickCard from "./ClickCard";
import SectionHeader from "./SectionHeader";
import SectionLayout from "./SectionLayout";
import ines from "../images/Ines01.png"
import sascha from "../images/Sascha01.png"
import Button from './Button'

const sectionTitle = "Über Uns";
const sectionSubTitle = "Engagement für zeitgenössische Kunst";
const sectionContent = "Lernen Sie uns kennen: die Förderkreisleitung und das Team um Ines Gräfin von der Schulenburg und Sascha Gustiné stellt sich vor."

const UeberUns = (props) => {
    return (
        <SectionLayout number={props.number}>
                <SectionHeader 
                    sectionTitle={sectionTitle}
                    sectionSubTitle={sectionSubTitle}
                    sectionContent={sectionContent}
                />
                <div className="flex flex-col md:flex-row justify-evenly p-10">
                    <ClickCard text="Ines Gräfin von der Schulenburg" image={ines} />
                    <ClickCard text="Sascha Gustiné" image={sascha} />
                </div>
                <Button text="Das gesamte Team" />
        </SectionLayout>

    );
};

export default UeberUns;
