import * as React from "react";
import Button from "./Button";
import SectionHeader from "./SectionHeader";
import SectionLayout from "./SectionLayout";

const sectionTitle = "Newsletter";
const sectionSubTitle = "Förderkreis-Newsletter - jetzt abonnieren!";
const sectionContent = "Mit unseren Förderkreis-Newslettern informieren wir Sie regelmäßig über alle wichtigen Veranstaltungen in Ihrer Kestner Gesellschaft."

const Newsletter = (props) => {

    const handleClick = () => {
        alert("the newsletter button just got clicked")
    }

    return (
        <SectionLayout id="newsletter" number={props.number}>
            <SectionHeader
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                sectionContent={sectionContent}
            />
            <Button onClick={handleClick} text="Newsletter abonnieren" />
        </SectionLayout>
    );
};

export default Newsletter;
