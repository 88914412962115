import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import SectionHeader from "./SectionHeader";
import SectionLayout from "./SectionLayout";

const sectionTitle = "Aktuelles";
const sectionSubTitle = "Lorem ipsum";
const sectionContent = ""

const Aktuelles = (props) => {

    return (
        <SectionLayout id="aktuelles" number={props.number}>
            <SectionHeader
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                sectionContent={sectionContent}
                />
                <div className="px-5 text-center">
                <p className="mb-3">Neueste Beiträge:</p>
                <StaticQuery
                      query={graphql`
                      query {
                        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 5) {
                          edges {
                            node {
                              frontmatter {
                                date(formatString: "MMMM DD, YYYY")
                                slug
                                title
                              }
                            }
                          }
                        }
                      }
                    `}
                render={data => (
                <ul>
                    { data.allMarkdownRemark.edges.map( (beitrag) => <Link className="text-blue-600" to={beitrag.node.frontmatter.slug}><li>{beitrag.node.frontmatter.title}</li></Link> )}
          
                </ul>
                )}
                />
                </div>
        </SectionLayout>
    );
};

export default Aktuelles;
