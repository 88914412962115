import React from 'react';

const ClickCard = (props) => {

    return (
        <div 
        className="shadow-lg bg-white border-b-2 border-red-600  min-h-0 w-full 
        md:w-60 mb-8 md:mb-0 flex flex-col">
            { props.icon ? 
            <div className="cardIconContainer h-2/3 mx-auto flex flex-col mt-5">
                 { props.icon } 
            </div>
            : undefined }

            {props.image ?
            <div className="cardImage w-full">
                 <img alt="" src={ props.image } /> 
            </div>
            :undefined }

            <div className="cardContent text-xl pb-5 pt-2">
                {props.text}
            </div>
        </div>
    )
}

export default ClickCard;