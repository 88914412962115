import * as React from "react";
import Aktuelles from "../components/Aktuelles";
import DefaultLayout from "../components/DefaultLayout";
import Editionen from "../components/Editionen";
import Home from "../components/Home";
import Mitgliedschaft from "../components/Mitgliedschaft";
import Newsletter from "../components/Newsletter";
import Rueckblicke from "../components/Rueckblicke";
import UeberUns from "../components/UeberUns";

const IndexPage = () => {
  return (
    <DefaultLayout>
      <title>Home Page</title>
      <Home />
      <Aktuelles number="1" />
      <Rueckblicke title="rueckblicke" number="2" />
      <Editionen number="3" />
      <UeberUns number="4" />
      <Newsletter number="5" />
      <Mitgliedschaft number="6" />
    </DefaultLayout>
  );
};

export default IndexPage;
