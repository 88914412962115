import * as React from "react";
import Button from "./Button";
import SectionHeader from "./SectionHeader";
import SectionLayout from "./SectionLayout";

const sectionTitle = "Mitgliedschaft"; // 11 too long
const sectionSubTitle = "Förderkreismitglied werden – Privilegien genießen!";
const sectionContent = "6 gute Gründe, Mitglied im Förderkreis der Kestner Gesellschaft zu werden. Lassen Sie sich überzeugen."

const Mitgliedschaft = (props) => {

    return (
        <SectionLayout id="mitgliedschaft" number={props.number}>
            <SectionHeader
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                sectionContent={sectionContent}
            />
            <Button text="Mitglied werden" />
        </SectionLayout>
    );
};

export default Mitgliedschaft;
