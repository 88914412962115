import * as React from "react";
import getIcon from "../utils/getIcon";
import ClickCard from "./ClickCard";
import SectionHeader from "./SectionHeader";
import SectionLayout from "./SectionLayout";


const sectionTitle = "Rückblicke";
const sectionSubTitle = "Newsletter- und Event-Archiv";
const sectionContent = "Berichte und Fotos zu Reisen, Exkursionen oder der letzten Party. Lesen und sehen Sie alles über die zahlreichen gemeinsamen Aktivitäten im Förderkreis und stöbern Sie in unserem Newsletter-Archiv."



const Rueckblicke = (props) => {
    return (
        <SectionLayout id="rueckblicke" number={props.number}>
            <SectionHeader
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                sectionContent={sectionContent}
            />
            <div className="flex flex-col md:flex-row justify-evenly p-10">
                <ClickCard text="Newsletter" icon={getIcon("mail")} />
                <ClickCard text="Events"  icon={getIcon("calendar")} />
            </div>
        </SectionLayout>
    );
};

export default Rueckblicke;
