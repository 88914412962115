import * as React from "react";


const SectionLayout = (props) => {
  return (
    <div id={props.id} className={`"m mb-5 py-8 flex ${props.number % 2 === 0 ? "bg-gray-50" : "" }`}>
      <div className="mx-auto text-center max-w-screen-md">
        <div className="pt-5">{props.children}</div>
      </div >
    </div>
  );
};

export default SectionLayout;
